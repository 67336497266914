<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      scrollable
      max-width="800"
      @click:outside="init"
      @keydown="$event.key === 'Escape' ? init() : ''"
    >
      <v-card>
        <v-card-title
          class="d-flex align-center pt-0 pb-0 justify-space-between"
        >
          <span class="text-h5">
            {{ getType().name }}등록
          </span>
          <div class="header-text-box">
            <v-text-field
              class="mt-5"
              label="제목"
              outlined
              :rules="[titleRule]"
              v-model="title"
              dense
            ></v-text-field>
          </div>
        </v-card-title>
        <v-card-text class="content-wrap">
          <div class="content-box">
            <div class="section-wrap">
              <div class="section">
                <p class="tit">
                  {{ getType().type === 2 ? '관리자' : '영업담당자' }}
                </p>
                <div>
                  <div v-if="manager !== null">
                    {{ manager.fullName }}
                    <v-btn small elevation class="mb-1 ml-2"
                      @click="getManagerList(), managerPicker = true">
                      변경
                    </v-btn>
                  </div>
                  <v-btn v-else small elevation color="success"
                    @click="getManagerList(), managerPicker = true">
                    {{ getType().type === 2 ? '관리자' : '영업담당자' }} 선택
                  </v-btn>
                  <v-card class="search-picker"
                    v-click-outside="managerPickerInit"
                    v-if="managerPicker === true"
                  >
                    <v-text-field
                      :autofocus="true"
                      dense
                      flat
                      hide-details
                      v-model="managerText"
                      :placeholder="`${getType().type === 2 ? '관리자' : '영업담당자'} 검색`"
                      @input="managerFiltering"
                      color="black"
                      outlined
                      solo
                    ></v-text-field>
                    <v-list v-if="managerFilter.length > 0">
                      <v-list-item
                        v-for="(row, i) in managerFilter"
                        :key="i"
                        dense
                        nav
                        link
                        @click="setManager(row)"
                      >
                        <v-list-item-title>
                          <v-chip small class="pr-1 pl-1 mr-1" link outlined label>
                            {{ row.group }}
                          </v-chip>
                          {{ row.name }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                    <v-list v-else-if="managerFilter.length === 0">
                      <v-list-item dense>
                        <v-list-item-title>{{
                          managerText == null
                          || managerText.trim().length === 0
                          ?'검색어를 입력해주세요.'
                          :'데이터가 없습니다.'
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </div>
              </div>
              <div class="section" v-if="this.manager !== null">
                <p class="tit">누적 환수금</p>
                <span>{{ comm.priceToString(this.redemptionAmount * -1) }}</span>
              </div>
              <div class="section" v-if="getType().type === 0">
                <p class="tit">종류</p>
                <v-radio-group
                  @change="$emit('update:method', $event)"
                  dense
                  row
                  :value="method"
                  hide-details
                  class="mt-0 mr-2"
                >
                  <v-radio
                    label="현금"
                    :value="0"
                  ></v-radio>
                  <v-radio
                    label="카드"
                    :value="1"
                  ></v-radio>
                </v-radio-group>
              </div>
              <div class="section" v-else-if="getType().type === 2 && manager !== null">
                <p class="tit">담당그룹</p>
                <div v-if="headGroups.length > 0">
                  <v-chip
                    class="ml-1"
                    close
                    outlined
                    v-for="(row, index) in headGroups"
                    @click:close="
                      headGroups = headGroups.filter((r, i) => i !== index);
                    "
                    :key="index"
                  >{{ row.name }}</v-chip>
                </div>
                <div v-else>
                  <v-chip
                    small label link
                    @click="headGroups = manager.headGroups"
                  >
                    담당그룹 불러오기
                  </v-chip>
                </div>
              </div>
              <div class="section">
                <p class="tit">기간</p>
                <div v-if="startDate === null || endDate === null">
                  <v-btn
                    small elevation
                    @click="
                      startDate = time.moment().day(-6).format('YYYY-MM-DD');
                      endDate = time.moment().day(0).format('YYYY-MM-DD');
                      dates = [];
                      datePicker = false;
                    "
                  >
                    지난 주
                  </v-btn>
                  <v-btn
                    small class="ml-2" elevation
                    @click="
                      startDate = time.moment().add(-1, 'M').startOf('M').format('YYYY-MM-DD');
                      endDate = time.moment().add(-1, 'M').endOf('M').format('YYYY-MM-DD');
                      dates = [];
                      datePicker = false;
                    "
                  >
                    지난 달
                  </v-btn>
                  <v-menu
                    v-model="datePicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto"
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        small class="ml-2" elevation
                        v-bind="attrs"
                        v-on="on"
                      >
                        직접선택
                      </v-btn>
                    </template>
                    <v-date-picker
                      :max="time.moment().format('YYYY-MM-DD')"
                      v-model="dates"
                      range
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        depressed
                        :disabled="dates.length !== 2"
                        @click="
                          startDate = dates.sort()[0];
                          endDate = dates.sort()[1];
                          datePicker = false;
                          dates = [];
                        "
                      >
                        적용
                      </v-btn>
                    </v-date-picker>
                  </v-menu>

                </div>
                <v-chip
                  v-else
                  label
                  close
                  @click:close="startDate = null; endDate = null;"
                >
                  {{ `${startDate} ~ ${endDate} `}}
                </v-chip>
              </div>
              <div class="section">
                <div
                  class="empty-sale-data"
                  v-if="sales === null || sales.length <= 0"
                >
                  <span
                    v-if="
                      startDate === null
                      || endDate === null
                      || manager === null
                      || (getType().type === 2 && headGroups.length <= 0)
                    "
                  >
                    {{ getType().type === 1 ? '환불' : '매출' }}정보를 불러오기 전 위 정보를 입력해주세요.
                  </span>
                  <div v-else>
                    <p class="mb-0" v-if="sales !== null && sales.length <= 0">
                      조건에 해당하는 {{ getType().type === 1 ? '환불' : '매출' }}건이 없습니다.
                    </p>
                    <v-btn
                      v-else
                      color="success"
                      elevation
                      @click="getSales()"
                    >
                      {{ getType().type === 1 ? '환불' : '매출' }}정보 불러오기
                    </v-btn>
                  </div>
                </div>
                <div class="sales-outer" v-else>
                  <div class="d-flex mb-1">
                    <p class="tit">{{ getType().type === 1 ? '환불' : '매출' }}목록</p>
                    <v-chip
                      v-if="sales.length > 1"
                      class="pr-2 pl-2"
                      small elevation color="primary" link
                      :outlined="!(salesSelected.length === sales.length)"
                      @click="
                        salesSelected.length === sales.length
                        ? salesSelected = []
                        : salesSelected = sales.map((r) => r.no);
                      "
                    >
                      전체{{ salesSelected.length === sales.length ? '해제' : '선택' }}
                    </v-chip>
                  </div>
                  <div class="sales-wrap">
                    <div
                      class="sales-row d-flex justify-space-between align-center"
                      v-for="(item, index) in sales" :key="index"
                    >
                      <div class="d-flex align-center">
                        <v-checkbox
                          class="mt-0 pt-0"
                          v-model="salesSelected" :value="item.no" hide-details dense
                        />
                        <div class="d-flex align-center">
                          <v-tooltip top color="black">
                            <template #activator="{ on, attrs }">
                              <v-chip label color="secondary" outlined small
                                v-bind="attrs"
                                v-on="on"
                                class="pr-1 pl-1"
                                v-if="item.method === 1"
                              >
                                카드
                              </v-chip>
                              <v-chip label color="accent" outlined small
                                v-bind="attrs"
                                v-on="on"
                                class="pr-1 pl-1"
                                v-else-if="item.method === 0"
                              >
                                현금
                              </v-chip>
                            </template>
                            <span>
                              {{ getType().type === 1 ? '환불번호: ': '매출번호: ' }}
                              {{ item.no }}<br/>
                              고객: {{ item.customerName}}
                            </span>
                          </v-tooltip>
                        </div>
                        <div class="ml-2 d-flex align-center">
                          <v-chip
                            label outlined small class="pr-1 pl-1"
                            v-if="getType().type === 2"
                          >
                            {{ comm.getGroupFullPath(groups, item.group) }}
                          </v-chip>
                          <v-chip label outlined small class="pr-1 pl-1" v-else>
                            {{ comm.getGroupFullPath(groups, item.managerGroupNo, '그룹없음 ') }}
                            {{ item.managerName }}
                          </v-chip>
                        </div>
                        <div class="ml-2 d-flex align-center">
                          <v-chip
                            x-small color="info" label
                            v-if="item.productType === 0"
                            class="pr-1 pl-1 mr-1"
                          >
                            리딩
                          </v-chip>
                          <v-chip
                            x-small color="warning" label
                            v-else-if="item.productType === 1"
                            class="pr-1 pl-1 mr-1"
                          >
                            코인
                          </v-chip>
                          {{ item.productName }}
                        </div>
                      </div>
                      <div class="ml-2 d-flex align-center">
                        <div class="d-flex align-center">
                          <v-chip
                            x-small color="success" label
                            v-if="item.type === 0"
                            class="pr-1 pl-1 mr-1"
                          >
                            매출
                          </v-chip>
                          <v-chip
                            x-small color="error" label
                            v-else-if="item.type === 1"
                            class="pr-1 pl-1 mr-1"
                          >
                            환불
                          </v-chip>
                          {{ comm.priceToString(item .amount) }}
                        </div>
                        <div class="ml-3 d-flex align-center" v-if="getType().type === 1">
                          <b>{{ item.percentage }}%</b>
                          <v-tooltip top color="black">
                            <template #activator="{ on, attrs }">
                              <v-icon
                                class="question ml-1"
                                small
                                color="warning"
                                v-on="on"
                                v-bind="attrs"
                              >mdi-help-circle-outline</v-icon>
                            </template>
                            <span>
                              원매출건에 대해 지급된 인센티브 비율입니다.<br/>
                              (원매출번호: {{ item.origin }} / 지급번호: {{ item.salaryNo }})
                            </span>
                          </v-tooltip>
                        </div>
                        <div class="ml-2 gray--text">
                          {{ time.makeLocalTime(item .date, 'date') }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="section" v-if="getType().type !== 1">
                <p class="tit">선택한 매출액</p>
                <b>{{ comm.priceToString(getSalesTotalAmount()) }}</b>
              </div>
              <div class="section" v-if="getType().type !== 1">
                <p class="tit">인센티브 비율(%)</p>
                <div class="section-text-box">
                  <v-text-field
                    hide-details
                    label="비율(1~100)"
                    outlined
                    :rules="[percentageRule]"
                    v-model="percentage"
                    dense
                  ></v-text-field>
                </div>
              </div>
              <div class="section">
                <div class="tit d-flex align-center">
                  추가요소
                  <v-tooltip top color="black">
                    <template #activator="{ on, attrs }">
                      <v-icon
                        class="question ml-1"
                        small
                        color="warning"
                        v-on="on"
                        v-bind="attrs"
                      >mdi-help-circle-outline</v-icon>
                    </template>
                    <span>
                      추가한 요소의 금액만큼 {{ getType().type === 1 ? '환수' : '지급' }}액이
                      {{ getType().type === 1 ? '감액' : '증액' }}됩니다.<br/>
                      (추가요소는 최대 5개까지 등록할 수 있습니다.)
                    </span>
                  </v-tooltip>
                </div>
                <v-tooltip top color="black" v-if="customs.length < 5">
                  <template #activator="{ on, attrs }">
                    <v-btn
                      @click="customAppenderInit(); customAppender = true"
                      v-bind="attrs"
                      v-on="on"
                      dense depressed fab x-small dark
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>추가</span>
                </v-tooltip>
                <v-card class="pay-form custom-form"
                  v-if="customAppender === true"
                >
                  <div class="input-wrap">
                    <div class="text-input-wrap">
                      <v-text-field
                        v-model="customName"
                        outlined
                        dense
                        label="항목명"
                        hide-details
                      />
                    </div>
                    <div class="text-input-wrap ml-2">
                      <price-text
                        v-model="customPrice"
                        outlined
                        dense
                        hide-details
                        label="금액(원)"
                        :rules="[priceRule]"
                      />
                    </div>
                  </div>
                  <div class="d-flex justify-end mt-3">
                    <v-btn
                      elevation
                      color="primary"
                      :disabled="
                        customName === null || customName.length <= 0
                        || customName.length > 30 || customPrice <= 0
                      "
                      @click="
                        customs.push({ name: customName, price: customPrice });
                        customAppenderInit();
                      "
                    >추가</v-btn>
                    <v-btn elevation @click="customAppenderInit" class="ml-2">취소</v-btn>
                  </div>
                </v-card>
              </div>
              <div class="custom-list" v-if="customs.length > 0">
                <div
                  class="custom-row"
                  v-for="(row, i) in customs" :key="i"
                >
                  <div class="custom-info">
                    <v-chip small label>
                      {{ row.name }}
                    </v-chip>
                    <span class="custom-text ml-3">{{ comm.priceToString(row.price) }}</span>
                  </div>
                  <v-tooltip top color="black">
                    <template #activator="{ on, attrs }">
                      <v-btn fab x-small color="error" class="ml-2" elevation
                        v-bind="attrs"
                        v-on="on"
                        @click="customs = customs.filter((r, index) => i !== index)"
                      >
                        <v-icon small>mdi-minus</v-icon>
                      </v-btn>
                    </template>
                    <span>추가요소 삭제</span>
                  </v-tooltip>
                </div>
              </div>
              <div class="section" v-if="getType().type !== 1">
                <p class="tit">환수금 차감</p>
                <div class="section-text-box">
                  <price-text
                    v-model="deduction"
                    outlined
                    dense
                    hide-details
                    label="금액(원)"
                    :rules="[deductionPriceRule]"
                  />
                </div>
              </div>
              <div class="section pt-4">
                <p class="tit">{{ getType().type === 1 ? '환수' : '지급' }}액</p>
                <b style="font-size: 1.1rem" v-if=" getType().type === 1">
                  {{ comm.priceToString(getSalaryTotalAmount()) }}
                </b>
                <b style="font-size: 1.1rem" v-else>
                  {{ comm.priceToString(getSalaryTotalAmount()) }}
                </b>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions class="pb-6 pr-6 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            :disabled="registValidation()"
            @click="registSalary()"
          >
            등록
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
import time from '@/util/time';
import comm from '@/util/comm';
import PriceText from '../input/PriceText.vue';

export default {
  name: 'DlgSalesRegist',
  components: {
    PriceText,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
    refresh: {
      type: Function,
      required: true,
    },
    method: {
      type: Number,
    },
  },
  data: () => ({
    comm,
    groups: [],
    time,
    title: null,
    headGroups: [],
    managers: [],
    managerText: null,
    managerFilter: [],
    manager: null,
    managerPicker: false,
    startDate: null,
    endDate: null,
    dates: [],
    datePicker: false,
    sales: null,
    salesSelected: [],
    percentage: 10,
    customs: [],
    customAppender: false,
    customName: null,
    customPrice: 0,
    deduction: 0,
    redemptionAmount: 0,
    titleRule: (value) => {
      if (value === null || value.trim().length <= 0 || value.trim().length >= 25) {
        return '제목을 1자 이상 25자 미만으로 작성해주세요.';
      }
      return true;
    },
    percentageRule: (value) => {
      if (value === null || Number.isNaN(Number(value)) === true
        || value > 100 || value < 1) {
        return '1 ~ 100 사이의 숫자로만 입력해주세요.';
      }
      return true;
    },
    priceRule: (value) => {
      const val = Number(value.replaceAll(',', ''));
      if (val < 1 || val > 1000000000 || value === 'NaN') {
        return '1원 이상 10억 미만이어야 합니다.';
      }
      return true;
    },
  }),
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
    }),
  },
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
      confirm: 'dialog/confirm',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
    init() {
      this.$emit('update:show', false);
      this.$emit('update:type', 0);
      this.$emit('update:method', 0);
      this.managerPickerInit();
      this.title = null;
      this.manager = null;
      this.startDate = null;
      this.endDate = null;
      this.dates = [];
      this.datePicker = false;
      this.headGroups = [];
      this.sales = null;
      this.salesSelected = [];
      this.percentage = 10;
      this.customs = [];
      this.customAppenderInit();
      this.deduction = 0;
      this.redemptionAmount = 0;
    },
    getType() {
      let obj = { name: '급여', type: 0 };
      if (this.type === 0 && this.method === 2) {
        obj = { name: '관리자급여', type: 2 };
      } else if (this.type === 1) {
        obj = { name: '환수', type: 1 };
      }
      return obj;
    },
    managerPickerInit() {
      this.managersText = null;
      this.managers = [];
      this.managerFilter = [];
      this.managerPicker = false;
    },
    managerFiltering(e) {
      if (e != null && e.length > 0) {
        const result = this.managers.filter((r) => r.fullName.indexOf(e) !== -1);
        this.managerFilter = result;
      } else {
        this.managerFilter = [];
      }
    },
    setManager(manager) {
      this.progress(true);
      this.$socket.emit('salary.redemption.amount.get', { manager: manager.id }, (resp) => {
        if (resp.result === 'success') {
          const { amount, deduction } = resp.item[0];
          this.redemptionAmount = Number(amount) + Number(deduction);
          this.manager = manager;
          if (this.getType().type === 2) this.headGroups = manager.headGroups;
          this.managerPickerInit();
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    getManagerList() {
      this.managers = [];
      this.managerText = null;
      this.managerFilter = [];
      this.progress(true);
      this.$socket.emit('groups.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [false],
        filters: this.getType().type === 2 ? [] : [],
      }, (res) => {
        if (res.result === 'success') {
          this.groups = res.items.groups;
          this.$socket.emit('users.staffs.list.get', {
            page: 1,
            itemsPerPage: 0,
            sortBy: ['groupNo'],
            sortDesc: [false],
            filters: [{ condition: 'eq', column: 'is_sales', value: 1 }],
          }, (resp) => {
            if (resp.result === 'success') {
              resp.items.forEach((r) => {
                const groupName = comm.getGroupFullPath(this.groups, r.groupNo, '그룹없음 ');
                if (this.getType().type === 2) {
                  const groupList = comm.makeGroupList(this.groups);
                  const headGroups = groupList.reduce((arr, group) => {
                    if (r.id === group.head || r.id === group.deputyHead) {
                      arr.push({ name: group.name, no: group.value });
                    }
                    return arr;
                  }, []);
                  if (headGroups.length > 0) {
                    const manager = {
                      id: r.id,
                      fullName: `${groupName}${r.name}`,
                      name: r.name,
                      group: groupName,
                      groupNo: r.groupNo,
                      headGroups,
                    };
                    this.managers.push(manager);
                  }
                } else {
                  const manager = {
                    id: r.id,
                    fullName: `${groupName}${r.name}`,
                    name: r.name,
                    group: groupName,
                    groupNo: r.groupNo,
                  };
                  this.managers.push(manager);
                }
              });
              this.progress(false);
            } else {
              this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
              console.error(resp);
              this.progress(false);
            }
          });
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${res.code})`]);
          console.error(res);
          this.progress(false);
        }
      });
    },
    getSales() {
      this.progress(true);
      const options = this.makeOptions();
      this.sales = null;
      this.$socket.emit('sales.salary.list.get', options, (resp) => {
        if (resp.result === 'success') {
          this.sales = resp.items;
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    makeOptions() {
      const options = {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['date'],
        sortDesc: [true],
        filters: [
          {
            filters: [
              {
                filters: [
                  { condition: 'ge', column: 'date', value: time.makeUTCDate(this.startDate) },
                  {
                    where: 'and',
                    condition: 'le',
                    column: 'date',
                    value: `
                      ${time.makeUTCDate(this.endDate)} 23:59:59
                    `,
                  },
                ],
              },
            ],
          },
          {
            where: 'and',
            condition: 'eq',
            column: 'type',
            value: this.type,
          },
          {
            where: 'and',
            condition: 'eq',
            column: 'status',
            value: 1,
          },
        ],
      };
      if (this.getType().type === 2) {
        const filterObj = {
          where: 'and',
          filters: [],
        };
        const allChildrens = this.headGroups.map((r) => comm.getGroupChildrens(this.groups, r.no));
        const childrens = [...new Set([].concat(...allChildrens))];
        childrens.forEach((no, i) => {
          const obj = {
            condition: 'eq',
            column: 'groupNo',
            value: no,
          };
          if (i > 0) obj.where = 'or';
          filterObj.filters.push(obj);
        });
        options.filters.push(filterObj);
      } else if (this.getType().type === 1) {
        options.filters.push({
          where: 'and',
          condition: 'eq',
          column: 'manager',
          value: this.manager.id,
        }, {
          where: 'and',
          condition: 'eq',
          column: 'salaryStatus',
          value: 2,
        });
      } else {
        options.filters.push({
          where: 'and',
          condition: 'eq',
          column: 'manager',
          value: this.manager.id,
        }, {
          where: 'and',
          condition: 'eq',
          column: 'method',
          value: this.method,
        });
      }
      return options;
    },
    getSalesTotalAmount() {
      try {
        let result = 0;
        if (this.getType().type === 1) {
          this.sales.forEach((r) => {
            if (this.salesSelected.includes(r.no)) {
              result += Number(r.amount * (r.percentage / 100));
            }
          });
        } else {
          this.sales.forEach((r) => {
            if (this.salesSelected.includes(r.no)) {
              result += Number(r.amount);
            }
          });
        }
        return result;
      } catch {
        return 0;
      }
    },
    customAppenderInit() {
      this.customAppender = false;
      this.customName = null;
      this.customPrice = 0;
    },
    getSalaryTotalAmount() {
      try {
        const deduction = this.deduction === null
          || Number.isNaN(this.deduction) ? 0 : this.deduction;
        const salesAmount = this.getSalesTotalAmount();
        const customAmount = this.customs.reduce((acc, r) => (acc + r.price), 0);
        let result = 0;
        if (this.getType().type === 1) {
          if (salesAmount === 0) throw new Error();
          result = Math.round((salesAmount) + customAmount);
        } else {
          if (salesAmount === 0 && customAmount === 0) throw new Error();
          if (
            this.percentage === null || Number.isNaN(Number(this.percentage))
            || this.percentage > 100 || this.percentage < 1
          ) throw new Error();
          result = Math.round((salesAmount * (this.percentage / 100))
            + customAmount + (-deduction));
        }
        return result;
      } catch {
        return 0;
      }
    },
    getCustomTotalAmount() {
      try {
        return this.customs.reduce((acc, r) => (acc + r.price), 0);
      } catch {
        return 0;
      }
    },
    deductionPriceRule() {
      const val = this.deduction;
      if (
        Number.isNaN(val) || val < 0 || val > (this.redemptionAmount * -1)
        || val > (this.getSalesTotalAmount() + this.getCustomTotalAmount())
      ) {
        return false;
      }
      return true;
    },
    registValidation() {
      return (
        this.title === null || this.title.trim().length <= 0 || this.title.trim().length >= 25
        || this.manager === null
        || (
          this.getType().type !== 1
          && (
            this.percentage === null || Number.isNaN(Number(this.percentage))
            || this.percentage > 100 || this.percentage < 1
            || this.deductionPriceRule() === false
            || this.getSalaryTotalAmount() <= 0
          )
        )
        || (this.getType().type === 1 && this.getSalaryTotalAmount() >= 0)
      );
    },
    registSalary() {
      this.progress(true);
      const obj = {
        sales: this.salesSelected,
        manager: this.manager.id,
        title: this.title,
        type: Number(this.type),
        method: Number(this.method),
        percentage: Number(this.percentage),
        amount: Number(this.getSalaryTotalAmount()),
        deduction: this.deduction === null
          || Number.isNaN(this.deduction) ? 0 : this.deduction,
        custom: this.customs.length > 0 ? { ...this.customs } : {},
      };
      this.$socket.emit('salary.add', obj, (resp) => {
        if (resp.result === 'success') {
          this.progress(false);
          this.alert(['success', `${this.getType().name}가 등록되었습니다.`]);
          this.init();
          this.refresh();
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val === true) {
          this.title = `${this.getType().name}(${time.moment().format('YYYY년 MM월 DD일')})`;
        }
      },
    },
    manager: {
      handler() {
        this.sales = null;
        this.salesSelected = [];
      },
    },
    headGroups: {
      handler() {
        this.sales = null;
        this.salesSelected = [];
      },
    },
    startDate: {
      handler() {
        this.sales = null;
        this.salesSelected = [];
      },
    },
    endDate: {
      handler() {
        this.sales = null;
        this.salesSelected = [];
      },
    },
    method: {
      handler() {
        this.sales = null;
        this.salesSelected = [];
      },
    },
  },
};
</script>
<style scoped lang="scss">
.content-wrap {
  overflow-y: unset;
  .content-box {
    border-top: 1px solid #ccc;
    margin-top: 0px;
    .custom-list {
      & + .section {
        border-top: 1px dashed #ccc;
      }
      .custom-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        padding-top:0px;
        .custom-info {
          display: flex;
          align-items: center;
          .custom-text {
            color: #262626;
          }
        }
      }
    }
    .section {
      .sales-outer {
        width: 100%;
        .sales-wrap {
          width: 100%;
          max-height: 350px;
          overflow-y: auto;
          border: 1px solid #ccc;
          // background-color: #807070;
          .sales-row {
            padding: 4px;
            padding-right: 8px;
            overflow-y: hidden;
            &:hover {
              background-color: #f2f2f2;
            }
          }
          .sales-row + .sales-row {
            border-top: 1px dashed #ccc;
          }
        }
      }
      .empty-sale-data {
        padding: 20px;
        background-color: #f2f2f2;
        text-align: center;
        width: 100%;
        border-radius: 10px;
      }
      position: relative;
      padding: 10px;
      display: flex;
      align-items: center;
      color: #333;
      justify-content: space-between;
      & + .section, & + .section-wrap {
        border-top: 1px dashed #ccc;
      }
      .pay-form {
        &.custom-form {
          min-width: 0px;
          top: -100px;
        }
        padding: 10px;
        min-width: 640px;
        max-width: 640px;
        position: absolute;
        right: 30px;
        z-index: 1;
        top: -150px;
        box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
        0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
        .v-list {
          max-height: 100px;
          overflow: auto;
        }
        &.search-picker-wide {
          min-width: 300px;
          max-width: 800px;
        }
      }
      .search-picker {
        width: 250px;
        max-width: 250px;
        position: absolute;
        right: 30px;
        z-index: 1;
        top:8px;
        box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
        0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
        .v-list {
          max-height: 100px;
          overflow: auto;
        }
        &.search-picker-wide {
          min-width: 300px;
          max-width: 800px;
        }
      }
    }
    .section-wrap {
      & + .section, & + .section-wrap {
        border-top: 1px dashed #ccc;
      }
      .section + .section {
        border-top: 1px dashed #ccc;
      }
    }
    .tit {
      font-size: .9rem;
      font-weight: 500;
      color: #262626;
      margin: 0;
      margin-right: 10px;
    }
  }
}
.refund-file-wrap {
  max-width: 640px;
  .refund-files {
    max-width: 600px;
    flex-wrap: wrap;
  }
}
.total-wrap {
  border-top:1px dashed #ccc;
  padding: 10px 0px;
  .total-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    &.total-row-dot {
      border-bottom: 1px dashed #ccc;
      margin-bottom:15px;
      padding-bottom:15px;
    }
    .tit {
      font-size: .9rem;
      font-weight: 500;
      color: #262626;
      margin: 0;
      margin-right: 10px;
    }
    .total-result {
      font-weight: 500;
      font-size: 1.1rem;
      color:#262626;
      margin:0;
    }
    .total-sub {
      font-size: .9rem;
      color:#262626;
      margin:0;
    }
  }
};
.input-wrap {
  display: flex;
  align-items: center;
  .text-input-wrap {
    width: 200px;
  }
}
.pay-input-wrap {
  .text-input-wrap {
    width: 100%;
  }
}
.search-btn {
  position: absolute;
  top:6px;
  right: 6px;
}
.file-input {
  display: none;
}
.picker-wrap {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  .search-picker {
    min-width: 300px;
    max-width: 800px;
    position: absolute;
    right: 60px;
    z-index: 1;
    top:-2px;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    .v-list {
      max-height: 200px;
      overflow: auto;
    }
  }
}
.header-text-box {
  width: 400px;
}
.gray--text {
  color: #888;
}
</style>
