<template>
  <div class="contents-section">
    <div class="title-section">
      <h2 class="d-flex align-start">
        급여지급대장
        <v-tooltip
          right
          color="black"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              :loading="loading"
              icon
              class="mt-1 ml-1"
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="getSummary()"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>새로고침</span>
        </v-tooltip>
      </h2>
      <div>
        <v-btn
          depressed
          color="success"
          @click="registDlg.type = 0, registDlg.show = true"
        >
          급여등록
        </v-btn>
        <v-btn
          class="ml-2"
          depressed
          color="error"
          @click="registDlg.type = 1, registDlg.show = true"
        >
          환수등록
        </v-btn>
        <v-btn
          class="ml-2"
          depressed
          color="primary"
          @click="registDlg.type = 0, registDlg.method = 2, registDlg.show = true"
        >
          관리자급여등록
        </v-btn>
      </div>
    </div>
    <div class="main-section">
      <search-filter
        :dateFilter="dateFilter"
        :radioFilters="radioFilters"
        :search="search"
        type="server"
        searchPlaceHolder="번호/영업담당자"
        @updateSearch="updateSearch"
        @updateRadio="updateRadio"
      />
      <v-row
        justify="space-between"
        align="center"
        class="mr-0 ml-0 mt-0 mb-0"
      >
        <v-col>
          <v-radio-group
            @change="options.itemsPerPage = $event"
            dense
            row
            :value="options.itemsPerPage"
            hide-details
          >
            <v-radio
              small
              label="50개씩 보기"
              :value="50"
            ></v-radio>
            <v-radio
              small
              label="100개씩 보기"
              :value="100"
            ></v-radio>
            <v-radio
              small
              label="500개씩 보기"
              :value="500"
            ></v-radio>
            <v-radio
              small
              label="1000개씩 보기"
              :value="1000"
            ></v-radio>
            <v-radio
              small
              label="3000개씩 보기"
              :value="3000"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <data-table
        :headers="headers"
        :items="lists"
        :loading="loading"
        itemKey="no"
        :returnObject="true"
        class="elevation-0"
        :sorts.sync="sorts"
        :selected.sync="selected"
        :selectable="true"
        :options="options"
      >
        <template #status="{ item }">
          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <div class="mr-1">
                <v-chip
                  class="pl-1 pr-1"
                  small
                  label
                  :color="item.status === 0 ? 'grey'
                  : item.status === 1 ? 'info'
                  : 'primary'"
                  v-bind="attrs"
                  :outlined="item.status === 1"
                  v-on="on"
                >
                  {{
                    item.status === 0
                    ? '미승인'
                    : item.status === 1
                    ? '승인요청'
                    : '승인완료'
                  }}
                </v-chip>
              </div>
            </template>
            <v-list>
              <v-list-item
                class="options"
                v-if="item.status === 0"
                @click="updateSalary(item, 1)"
              >승인요청</v-list-item>
              <v-list-item
                class="options"
                v-if="item.status === 1"
                @click="updateSalary(item, 0)"
              >미승인으로 변경</v-list-item>
              <v-list-item
                class="options"
                @click="getHistories(
                  'salary.update',
                  'status',
                  '승인이력',
                  item,
                )"
              >
                승인이력열람
              </v-list-item>
              <v-list-item
                v-if="item.status === 0"
                class="options error--text"
              >
                삭제
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template #percentage="{ item }">
          {{ item.percentage }}%
        </template>
        <template #manager="{ item }">
          <div class="d-flex align-center">
            <v-chip
              class="pl-1 pr-1 mr-1 text-center"
              small
              label
              outlined
            >{{ comm.getGroupFullPath(groups, item.managerGroupNo, '그룹없음') }}</v-chip>
            {{ item.managerName }}
            <v-tooltip
              top
              color="black"
            >
              <template #activator="{ on: tooltip }">
                <v-btn
                  small
                  icon
                  v-on="{ ...tooltip }"
                  @mouseenter="getRedemptionAmount(item.manager);"
                >
                  <v-icon
                    small
                    color="error"
                  >mdi-database</v-icon>
                </v-btn>
              </template>
              <span v-if="redemptionAmount === null">
                누적환수금 조회중...
              </span>
              <span v-else>
                {{ `누적환수금: ${comm.priceToString(redemptionAmount)}` }}
              </span>
            </v-tooltip>
          </div>
        </template>
        <template #custom="{ item }">
          <span
            v-if="item.custom === '{}'"
            class="gray--text"
          >
            -
          </span>
          <div v-else-if="Object.values(JSON.parse(item.custom)).length > 0">
            <v-tooltip
              top
              color="black"
            >
              <template #activator="{ on: tooltip }">
                <div v-on="{ ...tooltip }">
                  {{ `${JSON.parse(item.custom)[0].name}` }}
                  <span v-if="Object.values(JSON.parse(item.custom)).length > 1">
                    {{ `등 ${Object.values(JSON.parse(item.custom)).length} 건` }}
                  </span>
                </div>
              </template>
              <span>
                <span
                  v-for="( item, index ) in Object.values(JSON.parse(item.custom))"
                  :key="index"
                >
                  {{ `${item.name}: ${comm.priceToString(item.price)}` }}<br />
                </span>
              </span>
            </v-tooltip>
          </div>
          <span
            v-else
            class="gray--text"
          >
            -
          </span>
        </template>
        <template #amount="{ item }">
          <span>{{ comm.priceToString(item.amount) }}</span>
        </template>
        <template #sales="{ item }">
          <v-chip
            small
            link
            outlined
            class="pr-2 pl-2"
            @click="salesInfoView(item)"
          >
            열람
          </v-chip>
        </template>
        <template #creator="{ item }">
          <div class="d-flex align-center">
            <v-chip
              class="pl-1 pr-1 mr-1"
              small
              label
              outlined
            >{{ comm.getGroupFullPath(groups, item.creatorGroupNo, '그룹없음') }}</v-chip>
            {{ item.creatorName }}
          </div>
        </template>
        <template #created="{ item }">
          <span>{{ time.makeLocalTime(item.created, 'sec')}}</span>
        </template>
      </data-table>
      <div class="text-center pt-4 d-flex justify-center align-center">
        <v-btn
          icon
          v-if="Math.ceil(totalItems / options.itemsPerPage) > 1"
          @click="options.page = 1"
          :disabled="options.page === 1"
        >
          <v-icon>mdi-chevron-double-left</v-icon>
        </v-btn>
        <v-pagination
          v-if="options.itemsPerPage > 0"
          small
          color="primary"
          v-model="options.page"
          :length="Math.ceil(totalItems / options.itemsPerPage)"
          totalVisible="15"
          depressed
        ></v-pagination>
        <v-btn
          icon
          v-if="Math.ceil(totalItems / options.itemsPerPage) > 1"
          @click="options.page = Math.ceil(totalItems / options.itemsPerPage)"
          :disabled="options.page === Math.ceil(totalItems / options.itemsPerPage)"
        >
          <v-icon>mdi-chevron-double-right</v-icon>
        </v-btn>
      </div>
    </div>
    <v-dialog
      v-model="salesInfoDlg.show"
      width="auto"
      @click:outside="salesInfoDlgInit()"
      @keydown="$event.key === 'Escape' ? salesInfoDlgInit() : ''"
    >
      <v-card class="log-wrap">
        <div
          class="tit d-flex align-center"
          v-if="salesInfoDlg.item !== null"
        >
          매출정보
          <v-tooltip
            top
            color="black"
          >
            <template #activator="{ on }">
              <div
                class="ml-4 d-flex align-center justify-end"
                v-on="on"
              >
                <v-chip
                  v-if="salesInfoDlg.item.type === 1"
                  color="error"
                  x-small
                  label
                  class="pr-1 pl-1"
                >
                  환수
                </v-chip>
                <div
                  class="d-flex justify-center"
                  v-else-if="salesInfoDlg.item.type === 0 && salesInfoDlg.item.method !== 2"
                >
                  <v-chip
                    color="success"
                    x-small
                    label
                    class="pr-1 pl-1"
                  >
                    급여
                  </v-chip>
                  <v-chip
                    label
                    color="secondary"
                    outlined
                    x-small
                    class="pr-1 pl-1"
                    v-if="salesInfoDlg.item.method === 1"
                  >카드</v-chip>
                  <v-chip
                    label
                    color="accent"
                    outlined
                    x-small
                    class="pr-1 pl-1"
                    v-else-if="salesInfoDlg.item.method === 0"
                  >현금</v-chip>
                </div>
                <v-chip
                  v-else-if="salesInfoDlg.item.type === 0 && salesInfoDlg.item.method === 2"
                  color="primary"
                  x-small
                  label
                  class="pr-1 pl-1"
                >
                  관리자
                </v-chip>
                <p class="ml-1 sub mb-0 mt-0">{{ salesInfoDlg.item.title }}</p>
              </div>
            </template>
            <span>번호: {{ salesInfoDlg.item.no }}</span>
          </v-tooltip>
        </div>
        <div
          class="log log-sale"
          v-if="salesInfoDlg.item !== null && salesInfoDlg.data.length > 0"
        >
          <div class="sales-wrap">
            <div
              class="sales-row d-flex justify-space-between align-center"
              v-for="(sale, index) in salesInfoDlg.data"
              :key="index"
            >
              <div class="d-flex align-center">
                <div class="d-flex align-center">
                  <v-tooltip
                    top
                    color="black"
                  >
                    <template #activator="{ on, attrs }">
                      <v-chip
                        label
                        color="secondary"
                        outlined
                        small
                        v-bind="attrs"
                        v-on="on"
                        class="pr-1 pl-1"
                        v-if="sale.method === 1"
                      >
                        카드
                      </v-chip>
                      <v-chip
                        label
                        color="accent"
                        outlined
                        small
                        v-bind="attrs"
                        v-on="on"
                        class="pr-1 pl-1"
                        v-else-if="sale.method === 0"
                      >
                        현금
                      </v-chip>
                    </template>
                    <span>
                      {{ salesInfoDlg.item.type === 1 ? '환불번호: ': '매출번호: ' }}
                      {{ sale.no }}<br />
                      고객: {{ sale.customerName}}
                    </span>
                  </v-tooltip>
                </div>
                <div class="ml-2 d-flex align-center">
                  <v-chip
                    label
                    outlined
                    small
                    class="pr-1 pl-1"
                    v-if="salesInfoDlg.item.type === 2"
                  >
                    {{ comm.getGroupFullPath(groups, sale.group) }}
                  </v-chip>
                  <v-chip
                    label
                    outlined
                    small
                    class="pr-1 pl-1"
                    v-else
                  >
                    {{ comm.getGroupFullPath(groups, sale.managerGroupNo, '그룹없음 ') }}
                    {{ sale.managerName }}
                  </v-chip>
                </div>
                <div class="ml-2 d-flex align-center">
                  <v-chip
                    x-small
                    color="info"
                    label
                    v-if="sale.productType === 0"
                    class="pr-1 pl-1 mr-1"
                  >
                    리딩
                  </v-chip>
                  <v-chip
                    x-small
                    color="warning"
                    label
                    v-else-if="sale.productType === 1"
                    class="pr-1 pl-1 mr-1"
                  >
                    코인
                  </v-chip>
                  {{ sale.productName }}
                </div>
              </div>
              <div class="ml-10 d-flex align-center">
                <div class="d-flex align-center">
                  <v-chip
                    x-small
                    color="success"
                    label
                    v-if="sale.type === 0"
                    class="pr-1 pl-1 mr-1"
                  >
                    매출
                  </v-chip>
                  <v-chip
                    x-small
                    color="error"
                    label
                    v-else-if="sale.type === 1"
                    class="pr-1 pl-1 mr-1"
                  >
                    환불
                  </v-chip>
                  {{ comm.priceToString(sale .amount) }}
                </div>
                <div
                  class="ml-3 justify-center"
                  v-if="salesInfoDlg.item.type === 1"
                >
                  <b>{{ sale.percentage }}%</b>
                  <v-tooltip
                    top
                    color="black"
                  >
                    <template #activator="{ on, attrs }">
                      <v-icon
                        class="question ml-1"
                        small
                        color="warning"
                        v-on="on"
                        v-bind="attrs"
                      >mdi-help-circle-outline</v-icon>
                    </template>
                    <span>
                      원매출건에 대해 지급된 인센티브 비율입니다.<br />
                      (원매출번호: {{ sale.origin }} / 지급번호: {{ sale.salaryNo }})
                    </span>
                  </v-tooltip>
                </div>
                <div class="ml-2 gray--text">
                  {{ time.makeLocalTime(sale .date, 'date') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="log"
          v-else
        >
          <p class="log-row mr-16"><span>매출정보가 없습니다.</span></p>
        </div>
      </v-card>
    </v-dialog>
    <!-- <v-dialog
      v-model="historyDlg.show"
      width="auto"
    >
      <v-card class="log-wrap">
        <div>
          <v-spacer />
          <v-btn
            icon
            @click="historyDlg.show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div>
          <v-data-table
            :headers="historyInfoHeaders"
            :items="historyDlg.data"
            :items-per-page="10"
            class="elevation-0"
            item-key="no"
          ></v-data-table>
        </div>
      </v-card>
    </v-dialog> -->

    <dlg-history
      :show.sync="historyDlg.show"
      :data="historyDlg.data"
      :title="historyDlg.title"
    />
    <dlg-salary-regist
      :show.sync="registDlg.show"
      :type.sync="registDlg.type"
      :method.sync="registDlg.method"
      :refresh="getSummary"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import SearchFilter from '@/components/layout/SearchFilter.vue';
// import PriceText from '@/components/input/PriceText.vue';
import DlgSalaryRegist from '@/components/dialog/DlgSalaryRegist.vue';
import DataTable from '@/components/datatable/DataTable.vue';
import time from '@/util/time';
import comm from '@/util/comm';
import DlgHistory from '@/components/dialog/DlgHistory.vue';

export default {
  name: 'Salary',
  components: {
    SearchFilter,
    // PriceText,
    DlgSalaryRegist,
    DataTable,
    DlgHistory,
  },
  data: () => ({
    comm,
    time,
    productName: '',
    registDlg: {
      show: false,
      type: 0,
      method: 0,
    },
    historyDlg: {
      show: false,
      data: [],
      title: '',
      sub: null,
    },
    loading: true,
    lists: [],
    selected: [],
    search: '',
    totalItems: 0,
    currentItem: [],
    radioFilters: {
      type: {
        key: 'type',
        name: '종류',
        value: '전체',
        data: [
          { name: '전체', value: '전체' },
          { name: '급여', value: 0 },
          { name: '급여(현금)', value: 2 },
          { name: '급여(카드)', value: 3 },
          { name: '환수', value: 1 },
          { name: '관리자급여', value: 4 },
        ],
      },
      status: {
        key: 'status',
        name: '상태',
        value: '전체',
        data: [
          { name: '전체', value: '전체' },
          { name: '미승인', value: 0 },
          { name: '승인요청', value: 1 },
          { name: '승인완료', value: 2 },
        ],
      },
    },
    dateFilter: {
      label: '등록일',
      startDate: time.moment().add('-1', 'Y').format('YYYY-MM-DD'),
      startPicker: false,
      endDate: time.moment().format('YYYY-MM-DD'),
      endPicker: false,
    },
    redemptionAmount: null,
    salesInfoDlg: {
      show: false,
      item: null,
      data: [],
    },
    sorts: {
      sortBy: ['created'],
      sortDesc: [true],
    },
    options: {
      page: 1,
      itemsPerPage: 50,
      sortBy: ['created'],
      sortDesc: [true],
      filters: [
        { condition: 'ge', column: 'created', value: time.makeUTCDate(time.moment().add('-1', 'Y').format('YYYY-MM-DD')) },
        {
          where: 'and',
          condition: 'le',
          column: 'created',
          value: `
            ${time.makeUTCDate(time.moment().format('YYYY-MM-DD'))} 23:59:59
          `,
        },
        {
          where: 'and',
          condition: 'inc',
          column: 'type',
          value: '',
        },
        {
          where: 'and',
          condition: 'inc',
          column: 'method',
          value: '',
        },
        {
          where: 'and',
          condition: 'inc',
          column: 'status',
          value: '',
        },
        {
          where: 'and',
          filters: [
            {
              condition: 'inc',
              column: 'managerName',
              value: '',
            },
          ],
        },
      ],
    },
    makeHistories: {
      status: (data) => {
        const result = [];
        // const thisType = vue.currentItem.type;
        const msgObj = {
          0: '미승인',
          1: '승인요청',
          2: '승인완료',
        };
        data.forEach((r) => {
          if (r.after.status !== undefined || r.after.date !== undefined) {
            const msgKey = r.after.status !== undefined ? r.after.status : 2;
            const msg = msgObj[String(msgKey)];
            result.push({ ...r, after: msg });
          }
        });
        return result;
      },
    },
  }),
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
      route: 'router/route',
    }),
    headers() {
      return [
        {
          text: '번호',
          value: 'no',
          sortable: false,
          width: 80,
        },
        {
          text: '상태',
          value: 'status',
          sortable: false,
          width: 100,
        },
        {
          text: '영업담당자',
          value: 'manager',
          sortable: false,
          width: 230,
        },
        {
          text: '제목',
          value: 'title',
          sortable: false,
          width: 250,
        },
        {
          text: '인센티브비율',
          value: 'percentage',
          sortable: false,
          width: 80,
        },
        {
          text: '추가요소',
          value: 'custom',
          sortable: false,
          width: 130,
        },
        {
          text: '지급합계',
          value: 'amount',
          sortable: true,
          width: 130,
        },
        {
          text: '매출정보',
          value: 'sales',
          sortable: false,
          width: 80,
        },
        {
          text: '등록인',
          value: 'creator',
          sortable: false,
          width: 200,
        },
        {
          text: '등록일시',
          value: 'created',
        },
      ];
    },
    historyInfoHeaders() {
      return [
        {
          text: '번호',
          align: 'center',
          sortable: true,
          value: 'no',
        },
        {
          text: '이름',
          align: 'center',
          sortable: true,
          value: 'name',
        },
        {
          text: '승인내역',
          align: 'center',
          sortable: false,
          value: 'after',
        },
        {
          text: '승인날짜',
          align: 'center',
          sortable: true,
          value: 'date',
        },
      ];
    },
  },
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
      confirm: 'dialog/confirm',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
    /* 검색 업데이트 */
    updateSearch(value) {
      this.search = value;
    },
    /* 라디오필터 업데이트 */
    updateRadio(value, key) {
      this.radioFilters[key].value = value;
    },
    /* 셀렉트필터 업데이트 */
    updateSelect(value, key) {
      this.selectFilters[key].value = value;
    },
    getList() {
      this.$socket.emit('salary.list.get', this.options, (resp) => {
        if (resp.result === 'success') {
          this.lists = resp.items;
          this.totalItems = this.lists.length;
          this.loading = false;
          if (this.options.page > Math.ceil(this.totalItems / this.options.itemsPerPage)) {
            this.options.page = 1;
          }
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.loading = false;
        }
      });
    },
    getSummary() {
      this.loading = true;
      this.$socket.emit('groups.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [false],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          this.groups = resp.items.groups;
          // this.selectFilters.groupNo.data = comm.makeGroupList(resp.items.groups, 'select');
          this.$socket.emit('salary.summary.get', {
            filters: this.options.filters,
          }, (res) => {
            if (res.result === 'success') {
              this.totalItems = res.item.count;
              this.getList();
            } else {
              this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${res.code})`]);
              console.error(res);
              this.progress(false);
            }
          });
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    // updateOptions(type, value) {
    //   this.options[type] = [value];
    // },
    getGroupList() {
      this.$socket.emit('groups.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [false],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          this.groups = resp.items.groups;
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    salesInfoDlgInit() {
      this.salesInfoDlg.item = null;
      this.salesInfoDlg.show = false;
      this.salesInfoDlg.data = [];
    },
    historyInfoInit() {
      this.historyDlg.show = false;
      this.historyDlg.data = [];
      this.historyDlg.title = '';
      this.historyDlg.sub = null;
    },
    getRedemptionAmount(manager) {
      this.redemptionAmount = null;
      this.$socket.emit('salary.redemption.amount.get', { manager }, (resp) => {
        if (resp.result === 'success') {
          const { amount, deduction } = resp.item[0];
          this.redemptionAmount = Number(amount) + Number(deduction);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.redemptionAmount = null;
        }
      });
    },
    salesInfoView(item) {
      this.progress(true);
      this.salesInfoDlgInit();
      this.$socket.emit('salary.sales.list.get', { no: item.no }, (resp) => {
        if (resp.result === 'success') {
          this.salesInfoDlg.data = [...resp.items];
          this.salesInfoDlg.item = item;
          this.salesInfoDlg.show = true;
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    updateSalary(item, status) {
      this.progress(true);
      this.$socket.emit('salary.update', { no: item.no, status }, (resp) => {
        if (resp.result === 'success') {
          this.getSummary(false);
        }
      });
      this.progress(false);
    },
    getHistories(action, columnName, title, item) {
      this.progress(true);
      this.currentItem = item;
      console.log(this.currentItem.no);
      this.$socket.emit('log.get', {
        action,
        key: String(this.currentItem.no),
      }, (resp) => {
        if (resp.result === 'success') {
          const filterData = this.makeHistories[columnName](resp.items);
          this.historyDlg.data = filterData;
          console.log(this.historyDlg.data);
          this.historyDlg.show = true;
          this.historyDlg.title = title;
          this.openHistoryInfoDlg();
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    openHistoryInfoDlg() {
      if (this.historyDlg.data !== null) {
        this.historyDlg.show = true;
      }
    },
    checkFuncPermission(funcName) {
      try {
        const userPermission = { ...this.userInfo.permission.finance.menu.salary.func };
        let result = false;
        if (
          userPermission[funcName] !== undefined
          && userPermission[funcName].bool === true
        ) result = true;
        return result;
      } catch (error) {
        console.error('checkFuncPermission error');
        return false;
      }
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val !== null) {
          this.getSummary();
        }
      },
    },
    options: {
      deep: true,
      handler() {
        if (this.userInfo != null && this.totalItems >= 0) {
          this.getSummary();
        }
      },
    },
    radioFilters: {
      deep: true,
      handler(val) {
        if (this.userInfo != null) {
          const type = val.type.value === '전체' ? '' : val.type.value;
          const status = val.status.value === '전체' ? '' : val.status.value;
          if (type === 0) { // 급여-전체
            this.options.filters[2].value = type;
            this.options.filters[3].condition = 'ne';
            this.options.filters[3].value = 2; // method = 2(관리자) 제외
          } else if (type === 2) { // 급여-현금
            this.options.filters[2].value = 0;
            this.options.filters[3].condition = 'eq';
            this.options.filters[3].value = 0;
          } else if (type === 3) { // 급여-카드
            this.options.filters[2].value = 0;
            this.options.filters[3].condition = 'eq';
            this.options.filters[3].value = 1;
          } else if (type === 4) { // 관리자 급여
            this.options.filters[2].value = 0;
            this.options.filters[3].condition = 'eq';
            this.options.filters[3].value = 2;
          } else { // 환수
            this.options.filters[2].value = type;
            this.options.filters[3].condition = 'inc';
            this.options.filters[3].value = '';
          }
          this.options.filters[4].value = status;
        }
      },
    },
    dateFilter: {
      deep: true,
      handler(val) {
        if (this.userInfo != null && val.endPicker === false && val.startPicker === false) {
          this.options.filters[0].value = time.makeUTCDate(val.startDate);
          this.options.filters[1].value = time.makeUTCDate(
            `${val.endDate} 23:59:29`,
          );
        }
      },
    },
    search: {
      handler(val) {
        if (this.userInfo != null) {
          this.options.filters[5].filters[0].value = val;
        }
      },
    },
  },
  mounted() {
  },
};
</script>

<style scoped lang="scss">
.gray--text {
  color: #aaa;
}
.small--text {
  font-size: 0.8rem;
}
.log-wrap {
  padding: 15px;
  .tit {
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 10px;
    margin: 0;
  }
  .sub {
    font-size: 0.9rem;
    margin-top: -8px;
    margin-bottom: 6px;
  }
  .log {
    max-height: 300px;
    overflow: auto;
    background-color: #f2f2f2;
    padding: 10px;
    &.log-sale {
      background-color: #fff;
      padding: 0px;
    }
    .log-row {
      margin: 0;
      display: flex;
      justify-content: space-between;
      & + .log-row {
        margin-top: 4px;
      }
      span {
        font-size: 0.8rem;
        color: #555;
        & + span {
          margin-left: 60px;
        }
      }
      span.log-span {
        color: #262626;
        font-weight: 500;
      }
    }
  }
}
.sales-wrap {
  width: 100%;
  .sales-row {
    font-size: 0.9rem;
    padding: 4px;
    padding-right: 8px;
    overflow-y: hidden;
    &:hover {
      background-color: #f2f2f2;
    }
  }
  .sales-row + .sales-row {
    border-top: 1px dashed #ccc;
  }
}
.options {
  font-size: 0.9rem;
  padding: 0px 20px;
  min-height: 34px;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
}
</style>
